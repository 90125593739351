import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

export const Footer = () => {
  const another = [
    {
      title: "Beranda",
      href: "#",
    },
    {
      title: "Tentang Kami",
      href: "#",
    },
    {
      title: "Layanan Kami",
      href: "#",
    },
    {
      title: "Program",
      href: "#",
    },
  ];

  return (
    <div className="min-h-screen relative overflow-hidden">
      <div className="bg-[#D9D9D9]/[16%] backdrop-blur-md min-h-[95vh] z-20 py-10 px-5 flex flex-col">
        <div className="container mx-auto my-auto">
          <img
            src="./assets/komitras logo.png"
            alt="logo"
            className="w-[150px] md:w-[200px]"
          />
          <div className="grid grid-cols-2 lg:grid-cols-6 gap-10">
            <div className="col-span-2 md:col-span-1 lg:col-span-2">
              <p className="md:w-4/5">
                Kami hadir demi memberikan pengalaman terbaik bagi transformasi
                digital bisnis Anda, melalui karya serta implementasi yang tepat
                dari teknologi terkini
              </p>
            </div>
            <div className="md:col-span-1 col-span-2">
              <h4>Lainnya</h4>
              <div className="grid my-5 gap-5">
                {another.map((item, i) => (
                  <Link
                    key={i}
                    to={item.href}
                    className="hover:text-primary text-gray"
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
            <div className="md:col-span-1 col-span-2">
              <h4>Kontak Kami</h4>
              <div className="grid my-5 gap-5">
                <Link to={""} className="hover:text-primary text-gray">
                  info@komitras.com
                </Link>
                <Link to={""} className="hover:text-primary text-gray">
                  021- 21275166
                </Link>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 lg:col-span-2">
              <h4>Menghubungi Kami</h4>
              <p className="md:w-5/6">
                Isi formulir di bawah dengan alamat email Anda, dan kami akan
                segera menghubungi Anda untuk membahas kebutuhan Anda. Terima
                kasih atas minat Anda
              </p>
              <div className="w-full md:w-3/4 border border-primary rounded-md grid grid-cols-12 p-1">
                <input
                  type="text"
                  placeholder="Your Email Here"
                  className="bg-transparent px-5 col-span-10 outline-none placeholder:tracking-[.4rem]"
                />
                <button className="bg-primary py-5 text-white flex items-center justify-center col-span-2 rounded-md">
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto mt-auto">
          <p className="text-primary">© 2023 PT. KOMITRAS</p>
        </div>
      </div>
      <img
        src="./assets/footer.svg"
        alt="blob"
        className="absolute -right-20 -bottom-20 -z-30"
      />
    </div>
  );
};
