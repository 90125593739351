import React from "react";
import { Link } from "react-router-dom";
import { RiArrowRightUpLine } from "react-icons/ri";

export const About = () => {
  return (
    <section className="min-h-screen container mx-auto space-y-5" id="about">
      <div className="bg-light_gray w-full min-h-[40vh] overflow-hidden relative rounded-xl p-10 lg:p-5 flex items-center justify-between flex-wrap">
        <div className="lg:w-[90%] w-full mx-auto space-y-4">
          <h2>Tentang Kami</h2>
          <h3 className="w-full lg:w-1/3">Solusi teknologi inovatif untuk Anda</h3>
          <p className="w-full lg:w-1/3 font-normal">
            Pesatnya perkembangan teknologi digital membuat kebutuhan terhadap
            solusi berbasis teknologi informasi turut meningkat secara
            signifikasi
          </p>
          <Link
            className="flex items-center gap-3
          py-4 justify-center text-white rounded-full w-full md:w-1/4 lg:w-1/6 bg-gradient-to-r from-red-600 via-red-500 to-red-400 backdrop-filter backdrop-blur-lg"
          >
            Hubungi Kami <RiArrowRightUpLine />
          </Link>
        </div>
        <img
          src="./assets/about.svg"
          alt="bg"
          className="absolute right-[5%] w-1/4 z-10"
        />
        <img
          src="./assets/lights.svg"
          alt="bg"
          className="absolute right-0 top-0 translate-x-[50%] translate-y-[-50%]"
        />
      </div>
      <div className="grid lg:grid-cols-2 gap-5">
        <div className="bg-light_gray w-full min-h-[40vh] rounded-xl p-10">
          <h3>Visi</h3>
          <p className="font-normal">Menjadi pusat transformasi digital di Indonesia, menyediakan solusi teknologi inovatif untuk mempercepat pertumbuhan bisnis lokal dan meningkatkan kualitas hidup masyarakat</p>
        </div>
        <div className="bg-light_gray w-full min-h-[40vh] rounded-xl p-10">
          <h3>Misi</h3>
          <p className="font-normal">
            <ul className="list-decimal ml-6 space-y-3">
              <li>Inovasi Berkelanjutan: Mengembangkan solusi teknologi inovatif yang terjangkau untuk memberdayakan bisnis lokal, meningkatkan efisiensi, produktivitas, dan daya saing mereka.</li>
              <li>Pemberdayaan UMKM: Menyediakan solusi IT yang mudah diakses bagi Usaha Mikro, Kecil, dan Menengah (UMKM) untuk membantu mereka beradaptasi dengan era digital, meningkatkan visibilitas dan penetrasi pasar.</li>
              <li>Pendidikan Teknologi yang Merata: Mendorong literasi digital di semua lapisan masyarakat dengan program pelatihan teknologi yang mudah diakses dan komprehensif.</li>
              <li>Kemitraan Lokal yang Kuat: Berkolaborasi dengan lembaga pemerintahan, swasta, dan organisasi non-profit untuk menciptakan ekosistem teknologi yang inklusif dan berkelanjutan di Indonesia.</li>
              <li>Pengembangan Talenta Lokal: Memberdayakan talenta lokal dengan kesempatan pengembangan karir dan pendidikan yang mendorong pertumbuhan industri IT dalam negeri.</li>
            </ul>
          </p>
        </div>
      </div>
    </section>
  );
};
