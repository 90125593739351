import React from "react";
import { RiRobot2Line } from "react-icons/ri";
import { FaRegLightbulb } from "react-icons/fa";
import { AiOutlineMobile } from "react-icons/ai";
import { FiServer, FiCloud } from "react-icons/fi";
import { GoShieldCheck } from "react-icons/go";

export const Service = () => {
  const data = [
    {
      title: "IT Consultant",
      desc: "Konsultan IT yang ahli dalam memberikan solusi teknologi terbaik untuk meningkatkan efisiensi dan produktivitas bisnis Anda.",
      icon: <FaRegLightbulb />,
    },
    {
      title: "AI Solutions",
      desc: "Implementasi kecerdasan buatan untuk mentransformasi bisnis Anda dengan solusi teknologi canggih dan adaptif.",
      icon: <RiRobot2Line />,
    },
    {
      title: "Software Development",
      desc: "Pengembangan perangkat lunak berkualitas tinggi yang didesain khusus sesuai kebutuhan Anda.",
      icon: <AiOutlineMobile />,
    },
    {
      title: "Hardware Services",
      desc: "Layanan hardware yang handal dan berkualitas untuk mendukung infrastruktur teknologi bisnis Anda.",
      icon: <FiServer />,
    },
    {
      title: "Cloud Services",
      desc: "Solusi layanan cloud untuk memperluas kemampuan dan kehandalan sistem teknologi perusahaan Anda.",
      icon: <FiCloud />,
    },
    {
      title: "Cyber Security Consultant",
      desc: "Konsultan keamanan siber untuk melindungi sistem dan data bisnis Anda dari ancaman keamanan digital.",
      icon: <GoShieldCheck />,
    },
  ];
  return (
    <section className="container mx-auto p-3 md:p-5 min-h-[80vh]" id="service">
      <h2 className="text-center">Apa yang kami tawarkan?</h2>
      <h3 className="text-center w-full md:w-1/2 mx-auto">
        Solusi Unggulan untuk Kemajuan Bisnis Anda
      </h3>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-10 mt-14">
        {data.map((item, i) => (
          <div
            key={i}
            className="relative z-0 bg-white grid grid-cols-4 md:grid-cols-5 gap-5 items-strecth p-3 md:px-5 py-10 min-h-[20vh] group hover:bg-primary transition-all rounded-md before:w-full before:h-20 before:bg-white hover:before:bg-primary before:transition-all hover:z-10 hover:before:z-0 before:-z-10 before:absolute before:bottom-[-15%] before:rounded-[100%] before:blur-3xl"
          >
            <div className="flex items-start md:justify-center md:col-span-1 col-span-2">
              <div className="w-3/4 md:w-full lg:w-2/3 transition-all aspect-square bg-primary text-white group-hover:bg-white group-hover:text-primary text-2xl md:text-4xl flex items-center justify-center rounded-full">
                {item.icon}
              </div>
            </div>
            <div className="col-span-4">
              <p className="text-primary group-hover:text-white font-semibold">
                {item.title}
              </p>
              <p>{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
