import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Header } from "../Header";
import { Footer } from "../Footer";

export const Layouts = ({ title, desc, children }) => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={desc} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://komitras.com/" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://komitras.com/" />
        <meta
          property="twitter:title"
          content={title}
        />
        <meta
          property="twitter:description"
          content={desc}
        />
        <link rel="canonical" href={location.pathname} />
      </Helmet>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
};
