import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HiOutlineMenuAlt3 } from "react-icons/hi";


export const Header = () => {
  const [activeSection, setActiveSection] = useState('');
  const [pops, setPops] = useState(false)
  const navigation = [
    {
      title: "Home",
      href: "#home",
    },
    {
      title: "Service",
      href: "#service",
    },
    {
      title: "About",
      href: "#about",
    },
    {
      title: "Program",
      href: "#program",
    },
    {
      title: "Mission",
      href: "#mission",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll('section');

      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 200;
        const sectionHeight = section.clientHeight;

        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          setActiveSection(section.getAttribute('id'));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div className="w-full bg-white/50 backdrop-blur-md fixed top-0 z-50">
      <div className="container mx-auto p-3 md:p-5 flex items-center justify-between">
        <div className="flex items-center gap-5">
          <img src="./assets/komitras.png" alt="logo" className="h-16" />
          <p className="font-bold text-xl text-primary">KOMITRAS</p>
        </div>
        <div className="hidden lg:flex items-center gap-5">
          {navigation.map((item, i) => (
            <a
              className={`group hover:bg-primary hover:text-white relative z-20 before:bg-white before:blur-3xl before:transition-all hover:before:bg-primary before:w-full before:h-full before:absolute before:top-0 before:left-0 before:z-10 hover:shadow-xl before:rounded-full transition-all px-8 py-3 rounded-full text-lg font-medium ${activeSection === item.href.slice(1) ? 'bg-primary text-white before:bg-primary shadow-xl' : ''}`}
              key={i}
              href={item.href}
            >
              {item.title}
            </a>
          ))}
        </div>
        <Link
          className="px-8 py-3 border border-primary rounded-full font-semibold hover:bg-primary hover:text-white transition-all hidden lg:block"
          to="#"
        >
          Contact
        </Link>
        <button className="lg:hidden block text-4xl" onClick={() => setPops(!pops)}><HiOutlineMenuAlt3 /></button>
        {
          pops && (
            <div className="fixed w-full h-screen z-40" onClick={() => setPops(false)}>
              <div className="bg-white shadow-sm w-1/2 md:w-1/4 fixed top-20 right-10 p-5" onClick={e => e.stopPropagation()}>
                {
                  navigation.map((item, i) => (
                    <a href={item.href} key={i} className={`block text-center p-5 ${activeSection === item.href.slice(1) ? 'bg-primary text-white' : ''}`}>{item.title}</a>
                  ))
                }
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};
