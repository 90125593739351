import React from "react";

export const Mission = () => {
  const data = [
    "Pemberdayaan Komunitas: Kami berkomitmen untuk memberdayakan komunitas lokal dengan teknologi yang dapat meningkatkan akses, membantu pengembangan ekonomi, dan mendukung kemandirian.",
    "Kesetaraan dan Inklusi: Kami mempromosikan kesetaraan gender, inklusi sosial, dan aksesibilitas dalam setiap solusi teknologi yang kami kembangkan.",
    "Keadilan dan Keterbukaan: Kami mendorong praktik bisnis yang adil, transparan, dan bertanggung jawab secara social.",
    "Inovasi untuk Perubahan Positif: Kami memprioritaskan inovasi teknologi yang dapat memberikan dampak positif pada masyarakat, terutama dalam pencapaian Tujuan Pembangunan Berkelanjutan.",
    "Pertanggung jawaban Lingkungan: Kami berkomitmen pada solusi teknologi yang ramah lingkungan, berkontribusi pada perlindungan lingkungan dan pengelolaan sumber daya yang berkelanjutan.",
  ];
  return (
    <section className="min-h-[40vh] bg-gradient-to-b from-white to-light_gray pb-10 px-5" id="mission">
      <div className="container mx-auto">
        <h2>Nilai yang kami terapkan</h2>
        <h3 className="lg:w-1/3 w-3/4">
          Akuntabilitas Lingkungan dalam Solusi Teknologi.
        </h3>
        <div className="grid md:grid-cols-3 gap-5">
          {data.map((item, i) => (
            <div key={i} className="p-5 bg-white rounded-md">
              <p className="text-gray">{item}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
