import React from "react";
import { Layouts } from "../../Components/Layouts";
import { Banner } from "../../Components/Home/Banner";
import { About } from "../../Components/Home/About";
import { Service } from "../../Components/Home/Service";
import { AutoScroll } from "../../Components/Home/About/autoScroll";
import { Program } from "../../Components/Home/Program";
import { Mission } from "../../Components/Home/Mission";

export default function Home() {
  const data =
    "Explore the comprehensive IT solutions offered by PT Komitras. We specialize in software development, AI implementation, cyber security consulting, and IT consultancy services to elevate your business.";

  const dataImage = [
    "undp.png",
    "bppt.png",
    "bukopin.png",
    "honda.png",
    "jki.png",
    "mnc.png",
    "pegadaian.png",
    "pertamina.png",
    "ppd.png",
    "rma.png",
    "rpx.png",
    "arrahman.png",
  ];
  return (
    <Layouts title={"PT Komitras - Your Trusted Partner in IT Consulting, Software Development, AI Implementation, and Cyber Security Consultancy"} desc={data}>
      <Banner />
      <Service />
      <About />
      <div className="w-full my-5">
        <AutoScroll images={dataImage} />
      </div>
      <Program/>
      <Mission/>
    </Layouts>
  );
}
