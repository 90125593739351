import React from "react";

export const AutoScroll = ({ images }) => {
  return (
    <div className="relative w-full overflow-hidden h-24">
      <div className="absolute flex">
        <section className="flex flex-shrink-0 gap-10 section min-w-0">
          {images.map((image, i) => (
            <div key={i}>
              <img
                src={`./assets/sponsors/${image}`}
                className="grayscale hover:grayscale-0 transition-all w-24 aspect-square object-contain"
                alt={image}
              />
            </div>
          ))}
        </section>
        <section className="flex flex-shrink-0 gap-10 section min-w-0">
          {images.map((image, i) => (
            <div key={i}>
              <img
                src={`./assets/sponsors/${image}`}
                className="grayscale hover:grayscale-0 transition-all w-24 aspect-square object-contain"
                alt={image}
              />
            </div>
          ))}
        </section>
        <section className="flex flex-shrink-0 gap-10 section min-w-0">
          {images.map((image, i) => (
            <div key={i}>
              <img
                src={`./assets/sponsors/${image}`}
                className="grayscale hover:grayscale-0 transition-all w-24 aspect-square object-contain"
                alt={image}
              />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};
