import React from "react";

export const Program = () => {
  const data = [
    "Melakukan pelatihan dan pemberdayaan untuk peningkatan Programmer Perempuan secara luring dan daring",
    "Membangun Kerjasama untuk pengembangan Solar Cell di PJU (Penerangan Jalan Umum)",
    "Membangun Platform Voice Bot sebagai fasilitas bagi penyandang Tuna Netra untuk berita digital (Voice command).",
    "Membangun Platform penerjemah Bahasa Isyarat (text-to-video, video- to-text) atau Platform untuk belajar Bahasa Isyarat",
  ];
  return (
    <section
      className="min-h-screen relative items-strech grid lg:grid-cols-2"
      id="program"
    >
      <img src="./assets/program.png" alt="program" className="my-auto" />
      <div className="absolute top-20 md:top-[23%] lg:left-[15%] bg-white/50 px-10 py-5 backdrop-blur-sm rounded-md md:w-auto w-full">
        <h2 className="text-2xl md:text-4xl lg:text-6xl lg:tracking-[2.5rem] font-bold text-primary">
          KOMITRAS
        </h2>
        <h2 className="text-2xl md:text-4xl lg:text-6xl text-outline font-bold lg:tracking-[2.5rem]">
          DEVELOPMENT
        </h2>
        <h2 className="text-2xl md:text-4xl lg:text-6xl font-bold text-primary lg:tracking-[2.5rem]">
          PROGRAMME
        </h2>
      </div>
      <div className="flex items-center justify-end p-5">
        <div className="lg:w-2/3 w-full">
          {data.map((item, i) => (
            <div
              key={i}
              className={`py-10 lg:w-2/3 hover:text-primary text-gray font-normal transition-all ${
                i === data.length - 1 ? "border-0" : "border-b"
              } border-[#EDECEC]`}
            >
              <p>{item}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
