import React from "react";
import { Link } from "react-router-dom";

export const Banner = () => {
  return (
    <section className="px-3 md:px-5 container mx-auto min-h-screen pt-20 flex items-center" id="home">
      <div>
        <div className="grid items-center justify-center gap-5">
          <h1 className="uppercase relative tracking-[1.2rem] md:tracking-[2.5rem] z-20">
            komitmen,
            <img
              src="./assets/circle.svg"
              alt="circle"
              className="absolute top-0 right-0 -translate-y-[20%] -translate-x-[40%] -z-10"
            />
          </h1>
          <h1 className="text-outline uppercase tracking-[1.2rem] md:tracking-[2.5rem] md:translate-x-[20%]">
            transparan
          </h1>
        </div>
        <div className="relative mt-28 rounded-xl">
          <div className="w-full absolute aspect-[5/3] md:aspect-[7/2] bg-gradient-to-r from-blue-700 to-transparent via-blue-500 opacity-20 rounded-xl"></div>
          <img
            src="./assets/clouds.png"
            alt="bg"
            className="w-full aspect-[5/3] md:aspect-[7/2] object-cover rounded-xl"
          />
          <img
            src="./assets/building.png"
            alt="bg"
            className="md:w-[48%] bottom-0 right-0 absolute object-cover rounded-xl"
          />
          <div className="absolute bottom-0 w-full flex items-strect justify-between">
            <div className="p-5">
              <p className="text-white text-xl font-semibold tracking-[1rem]">
                Location
              </p>
              <p className="text-white">
                JL. CINERE RAYA BLOK.40. NO.10, Kota Depok, Jawa Barat
              </p>
            </div>
            <div className="bg-white p-5 hidden lg:grid grid-cols-2 items-center gap-5 w-1/5 rounded-tl-lg">
              <Link className="text-lg capitalize font-normal text-center tracking-[.24rem]">
                LinkedIn
              </Link>
              <Link className="text-lg capitalize font-normal text-center tracking-[.24rem]">
                Instagram
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
